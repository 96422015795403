module.exports = {
    seo: {
        title : "Jenko - Article - From Idea to Product",
        description:  ""
    },
  intro: {
      title: "From Idea to\u00A0Product",
      desc: "How to turn your brilliant idea into a real money-making\u00A0product?"
  },
  articleHeader: {
      desc: "One of the significant features of the 21st century is broad range thinking that can break through all conventional frameworks. The thoughtful creativity that is changing the face of all sectors of the economy, slowly but surely. If you have a creative idea that succeeds in bringing something innovative that no one has thought of before, it’s very likely you have a gold mine in your hand. This is true even if you have an idea for a patent that will facilitate or shorten an existing process. Even if you master a well-established skill and but are convinced that you will be able to do something better than others, there is something to work with. However, in practice, most good ideas remain on the shelf… waiting to reappear in the mind of someone who has not only the idea but also the tools and the methods of turning the essence of an idea into practice. In this article, we have summarized the significant milestones for you, clear and to the point. Ready?\u00A0Taking\u00A0off!"
  },
  secP: {
      title: "How to develop a product\u00A0idea?",
      p: [
          'There is sometimes a tendency to think that the way to turn an idea into a material product varies from industry to industry. It does indeed branch out during the development and production phase, but its essential route remains almost completely identical in the initial stages. Whether we are talking about advertising product ideas, digital product ideas, service ideas or a product idea that incorporates a real physical appearance, they all start in the exact same place: brainstorming. An essential step that is super effective if you are looking for ideas for a product that does not\u00A0yet\u00A0exist.',
          'Brainstorming is the bailout stage: from an uncoated concept in your mind to actual raw materials you can start working on. The power of brainstorming is not just in flooding out the information, mapping it and documenting it but above all, it’s about the participants involved. It is indisputable that the whole is greater than its parts and this principle is well expressed in a well-executed brainstorming process. Especially when experienced professionals who know the length and breadth of the process and have been carrying it out daily for years are\u00A0involved.'
      ]
  },
  thrdP: {
      title: "Characterization and feasibility\u00A0study",
      p: [
          'Once we have chosen an idea and processed it from a general concept to a uniform and coherent idea, we can move on to the next essential stage: the characterization phase and the feasibility\u00A0study.',
          'Characterization is the stage at which the idea is formed. Here we map the idea at its identity level, at its operational level and at its business vision. In the characterization stage, we give life to our product, from a conceptual idea to a real entity. We work on elements such as mapping strengths and weaknesses in terms of the competitors, clarifying and prioritizing our target audience, touching on the functions involved, and more. To summarize in a sentence: The characterization phase formulates the DNA of our product into a clear and comprehensive\u00A0identity.'
      ]
  },
  frthP: {
      p: 'A feasibility study is an acute phase that can be the difference between a business that closes down or worse, a business that is closed with heavy losses and debts, and a successful business that smoothly and steadily reaches great heights. Here we’ll be touching on both strategy and tactics. Skilled professionals can ask the right questions and use innovative and effective tools to examine feasibility and shed light on the expected future of a product. The feasibility study touches parameters such as: researching competitors, characterizing the target audience and their profiles, what is their purchasing power? What is the worthwhile pricing range for this purchasing power while weighing development production costs? Supply versus demand, the expected share of profits in the various stages of the journey and in short, professional research that will pave the way to the most secure path to success. At this point, it is already practical to think of patent registration. A critical step that if you don’t pay attention to it in on time, you can torpedo the entire journey. Owning the intellectual property in the heart of the idea is a prerequisite for its implementation.'
  },
  ffthP: {
      title: "Development and production",
      p: 'We chose an idea and formulated an identity for it, gathered, tested, compared, and analyzed, what’s next? From here, we will choose the most viable option for product development according to the providers we have formulated in the feasibility study and approach them to build a prototype. The prototype is the mother and father of the product. It is the initial version that will pave the way to the next versions that will evolve over time and with use. At this stage, we will develop the product using the latest and most advanced technologies available to us and explore it in all scenarios and under all possible conditions. Our company’s specialty is to provide you with a stable and robust prototype ready to launch in front of investors and mass production that will spread your gospel to the world.'
  },
  sixP: {
      title: "Going out into the\u00A0playground",
      p: [
          'And so, in a few steps, although not so simple but perfectly clear, you have a finished initial version of your product in your hands and are ready to go out into the playground. Exciting, isn’t\u00A0it?',
          'At this stage, we are faced with challenges such as investor recruitment, mass financing or any other type of financial resources available to us in the economy. We will help you reach this moment safely and readily. Creating a presentation from a product idea, creating an impressive business plan and all the other tools that need to be in your toolbox to drive and conquer the investors who will feel lucky to invest in you. This stage also concerns mass production. We can refer you to the most relevant and worthwhile industries while fully managing and controlling the manufacturing process from the point of reference to the moment you have a line of ready-made products for sale and\u00A0marketing.'
      ]
  },
  concl: {
      title: "To Summarize",
      p: 'To summarize how to develop an idea into a product is not simple, but everything gets easier when there is a clear map of how to reach a destination. We are here to guide you through the first steps of concept selection and formulation, characterization, feasibility study, patent registration, professional prototype construction, development, financing, and manufacturing. We will accompany you hand in hand in a pleasant, safe and professional manner like we have so many times before with our customers whose products are benefitting many audiences today.'
  },
  dir: 'ltr'
};